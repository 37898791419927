import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

const Admin = () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
const Companies = () => import(/* webpackChunkName: "admin" */ '../components/admin/Companies.vue')
const Company = () => import(/* webpackChunkName: "admin" */ '../components/admin/AViewCompany.vue')
const Invoices = () => import(/* webpackChunkName: "admin" */ '../components/admin/Invoices.vue')
const Logs = () => import(/* webpackChunkName: "admin" */ '../components/admin/Logs.vue')
const UiElements = () => import(/* webpackChunkName: "admin" */ '../components/admin/UiElements.vue')
const Materials = () => import(/* webpackChunkName: "materials" */ '../views/Materials.vue')
const AViewMaterial = () => import(/* webpackChunkName: "materials" */ '../components/material/AViewMaterial.vue')
const Products = () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
const AViewProduct = () => import(/* webpackChunkName: "products" */ '../components/product/AViewProduct.vue')
const Purchases = () => import(/* webpackChunkName: "purchase" */ '../views/Purchases.vue')
const AViewPurchase = () => import(/* webpackChunkName: "purchase" */ '../components/purchase/AViewPurchase.vue')
const Suppliers = () => import(/* webpackChunkName: "purchase" */ '../views/Suppliers.vue')
const AViewSupplier = () => import(/* webpackChunkName: "purchase" */ '../components/suppliers/AViewSupplier.vue')
const Transfer = () => import(/* webpackChunkName: "transfer" */ '../views/Transfer.vue')
const AViewTransfer = () => import(/* webpackChunkName: "transfer" */ '../components/transfer/AViewTransfer.vue')
const Production = () => import(/* webpackChunkName: "production" */ '../views/Production.vue')
const AViewProduction = () => import(/* webpackChunkName: "production" */ '../components/production/AViewProduction.vue')
const Orders = () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue')
const AViewOrder = () => import(/* webpackChunkName: "orders" */ '../components/order/AViewOrder.vue')
const Customers = () => import(/* webpackChunkName: "orders" */ '../views/Customers.vue')
const AViewCustomer = () => import(/* webpackChunkName: "orders" */ '../components/customers/AViewCustomer.vue')
const Storages = () => import(/* webpackChunkName: "storages" */ '../views/Storages.vue')
const Writeoffs = () => import(/* webpackChunkName: "writeoff" */ '../views/Writeoffs.vue')
const AViewWriteoff = () => import(/* webpackChunkName: "writeoff" */ '../components/writeoff/AViewWriteoff.vue')
const Resources = () => import(/* webpackChunkName: "resources" */ '../views/Resources.vue')
const AViewResource = () => import(/* webpackChunkName: "resources" */ '../components/resource/AViewResource.vue')
const Audits = () => import(/* webpackChunkName: "audit" */ '../views/Audits.vue')
const AEditAudit = () => import(/* webpackChunkName: "audit" */ '../components/audit/AEditAudit.vue')
const Settings = () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
const Reports = () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
const ReportMaterialsStocks = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsStocks.vue')
const ReportProductsStocks = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsStocks.vue')
const ReportMaterialsHistory = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsHistory.vue')
const ReportProductsHistory = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsHistory.vue')
const ReportMaterialsTurnover = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsTurnover.vue')
const ReportProductsTurnover = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsTurnover.vue')
const ReportMaterialsAtDate = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsAtDate.vue')
const ReportProductsAtDate = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsAtDate.vue')
const ReportPurchasesVolume = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportPurchasesVolume.vue')
const ReportProductionVolume = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductionVolume.vue')
const ReportOrdersVolume = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportOrdersVolume.vue')
const ReportPurchasedMaterials = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportPurchasedMaterials.vue')
const ReportOrderedProducts = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportOrderedProducts.vue')
const ReportProducedProducts = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProducedProducts.vue')
const ReportUsedMaterials = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportUsedMaterials.vue')
const ReportMaterialsTurnoverRates = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsTurnoverRates.vue')
const ReportProductsTurnoverRates = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsTurnoverRates.vue')
const ReportResourcesHistory = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportResourcesHistory.vue')
const ReportResourcesTurnover = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportResourcesTurnover.vue')
const ReportProductsAbc = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductsAbc.vue')
const ReportMaterialsAbc = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportMaterialsAbc.vue')
const ReportProfitAndLoss = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProfitAndLoss.vue')
const ReportPurchaseCostDynamics = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportPurchaseCostDynamics.vue')
const ReportProductionCostDynamics = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportProductionCostDynamics.vue')
const ReportOrderPriceDynamics = () => import(/* webpackChunkName: "reports" */ '../components/reports/AReportOrderPriceDynamics.vue')
const Dashboard = () => import(/* webpackChunkName: "reports" */ '../views/Dashboard.vue')
const History = () => import(/* webpackChunkName: "reports" */ '../views/History.vue')
const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
const Register = () => import(/* webpackChunkName: "login" */ '../views/Register.vue')
const Onboarding = () => import(/* webpackChunkName: "login" */ '../views/Onboarding.vue')
const SetPassword = () => import(/* webpackChunkName: "login" */ '../views/SetPassword.vue')

Vue.use(VueRouter)

let debug = false

if (origin != 'http://localhost:8080') debug = false

VueRouter.prototype.open = function (event, routeObject) {
	// Method for opening a route in a new tab if ctrl or cmd is pressed
	if (event.ctrlKey === true || event.metaKey === true) {
		let routeData = this.resolve(routeObject)
		window.open(routeData.href, "_blank")
	} else {
		this.push(routeObject)
	}
}

// meta props:
// needAuth - этот экран доступен только авторизованным пользователям
// authRight - название права доступа, которое необходимо для доступа к этому экрану
// authDeny - этот экран доступен только неавторизованным пользователям

const routes = [
	{
		path: '/onboarding',
		name: 'Onboarding',
		component: Onboarding,
		meta: {
			title: 'Начало работы',
			needAuth: true,
			authDeny: false
		},
	},
	{
		path: '/',
		redirect: '/materials',
	},
	{
		path: '/profile',
		redirect: '/settings',
	},
	{
		path: '/purchase',
		redirect: '/purchases',
	},
	{
		path: '/purchase/item/:id',
		redirect: '/purchases/item/:id',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Вход',
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		meta: {
			title: 'Регистрация',
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/set-password/:link',
		name: 'SetPassword',
		component: SetPassword,
		meta: {
			title: 'Восстановление пароля',
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/materials',
		name: 'Materials',
		component: Materials,
		meta: {
			title: 'Материалы',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		},
	},
	{
		path: '/materials/item/:id',
		name: 'Material',
		component: Materials,
		meta: {
			title: 'Материалы',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		},
	},
	{
		path: '/materials/item/print/:id/:storageId/:storageName/:code',
		name: 'MaterialPrint',
		component: AViewMaterial,
		meta: {
			title: 'Материал',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		}
	},
	{
		path: '/products',
		name: 'Products',
		component: Products,
		meta: {
			title: 'Продукты',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/products/item/:id',
		name: 'Product',
		component: Products,
		meta: {
			title: 'Продукты',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/products/item/print/:id/:storageId/:storageName/:code',
		name: 'ProductPrint',
		component: AViewProduct,
		meta: {
			title: 'Продукт',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/purchases',
		name: 'Purchases',
		component: Purchases,
		meta: {
			title: 'Поставки',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/purchases/item/:id',
		name: 'Purchase',
		component: Purchases,
		meta: {
			title: 'Поставка',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/purchases/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'PurchasePrint',
		component: AViewPurchase,
		meta: {
			title: 'Поставка',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		}
	},
	{
		path: '/suppliers',
		name: 'Suppliers',
		component: Suppliers,
		meta: {
			title: 'Поставщики',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/suppliers/item/:id',
		name: 'Supplier',
		component: Suppliers,
		meta: {
			title: 'Поставка',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/suppliers/item/print/:id/:code',
		name: 'SupplierPrint',
		component: AViewSupplier,
		meta: {
			title: 'Поставка',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		}
	},
	{
		path: '/production',
		name: 'Productions',
		component: Production,
		meta: {
			title: 'Производство',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/production/item/:id',
		name: 'Production',
		component: Production,
		meta: {
			title: 'Производство',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/production/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'ProductionPrint',
		component: AViewProduction,
		meta: {
			title: 'Производство',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/orders',
		name: 'Orders',
		component: Orders,
		meta: {
			title: 'Заказы',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/orders/item/:id',
		name: 'Order',
		component: Orders,
		meta: {
			title: 'Заказ',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/orders/item/print/:id/:storageName/:code',
		name: 'OrderPrint',
		component: AViewOrder,
		meta: {
			title: 'Заказ',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		}
	},
	{
		path: '/customers',
		name: 'Customers',
		component: Customers,
		meta: {
			title: 'Покупатели',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/customers/item/:id',
		name: 'Customer',
		component: Customers,
		meta: {
			title: 'Покупатель',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/customers/item/print/:id/:code',
		name: 'CustomerPrint',
		component: AViewCustomer,
		meta: {
			title: 'Покупатель',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		}
	},
	{
		path: '/storages',
		name: 'Storages',
		component: Storages,
		meta: {
			title: 'Склады',
			needAuth: true,
			authRight: 'storagesView',
			authDeny: false
		},
	},
	{
		path: '/storages/item/:id',
		name: 'Storage',
		component: Storages,
		meta: {
			title: 'Склад',
			needAuth: true,
			authRight: 'storagesView',
			authDeny: false
		},
	},
	{
		path: '/transfer',
		name: 'Transfers',
		component: Transfer,
		meta: {
			title: 'Перемещения',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		},
	},
	{
		path: '/transfer/item/:id',
		name: 'Transfer',
		component: Transfer,
		meta: {
			title: 'Перемещение',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		},
	},
	{
		path: '/transfer/item/print/:id/:code',
		name: 'TransferPrint',
		component: AViewTransfer,
		meta: {
			title: 'Перемещение',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		}
	},
	{
		path: '/writeoff',
		name: 'Writeoffs',
		component: Writeoffs,
		meta: {
			title: 'Списания',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		},
	},
	{
		path: '/writeoff/item/:id',
		name: 'Writeoff',
		component: Writeoffs,
		meta: {
			title: 'Списание',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		},
	},
	{
		path: '/writeoff/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'WriteoffPrint',
		component: AViewWriteoff,
		meta: {
			title: 'Списание',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		}
	},
	{
		path: '/resources',
		name: 'Resources',
		component: Resources,
		meta: {
			title: 'Ресурсы',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		},
	},
	{
		path: '/resources/item/:id',
		name: 'Resource',
		component: Resources,
		meta: {
			title: 'Ресурсы',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		},
	},
	{
		path: '/resources/item/print/:id/:code',
		name: 'ResourcePrint',
		component: AViewResource,
		meta: {
			title: 'Ресурс',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		}
	},
	{
		path: '/audits',
		name: 'Audits',
		component: Audits,
		meta: {
			title: 'Инвентаризации',
			needAuth: true,
			authRight: 'auditsView',
			authDeny: false
		}
	},
	{
		path: '/audits/:id',
		name: 'AuditEdit',
		component: AEditAudit,
		props: true,
		meta: {
			title: 'Инвентаризация',
			needAuth: true,
			authRight: 'auditsView',
			authDeny: false
		}
	},
	{
		path: '/history',
		name: 'History',
		component: History,
		meta: {
			title: 'История',
			needAuth: true,
			authRight: 'historyView',
			authDeny: false
		}
	},
	{
		path: '/reports',
		name: 'Reports',
		component: Reports,
		meta: {
			title: 'Отчеты',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-stocks',
		name: 'ReportMaterialsStocks',
		component: ReportMaterialsStocks,
		meta: {
			title: 'Материалы на складе',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-stocks',
		name: 'ReportProductsStocks',
		component: ReportProductsStocks,
		meta: {
			title: 'Продукты на складе',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-history',
		name: 'ReportMaterialsHistory',
		component: ReportMaterialsHistory,
		meta: {
			title: 'Движение материала',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-history',
		name: 'ReportProductsHistory',
		component: ReportProductsHistory,
		meta: {
			title: 'Движение продукта',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-turnover',
		name: 'ReportMaterialsTurnover',
		component: ReportMaterialsTurnover,
		meta: {
			title: 'Обороты материалов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-turnover',
		name: 'ReportProductsTurnover',
		component: ReportProductsTurnover,
		meta: {
			title: 'Обороты продуктов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-at-date',
		name: 'ReportMaterialsAtDate',
		component: ReportMaterialsAtDate,
		meta: {
			title: 'Остатки материалов на дату',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-at-date',
		name: 'ReportProductsAtDate',
		component: ReportProductsAtDate,
		meta: {
			title: 'Остатки продуктов на дату',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/purchases-volume',
		name: 'ReportPurchasesVolume',
		component: ReportPurchasesVolume,
		meta: {
			title: 'Объем поставок',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/production-volume',
		name: 'ReportProductionVolume',
		component: ReportProductionVolume,
		meta: {
			title: 'Объем производства',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false

		},
	},
	{
		path: '/reports/orders-volume',
		name: 'ReportOrdersVolume',
		component: ReportOrdersVolume,
		meta: {
			title: 'Объем заказов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/purchased-materials',
		name: 'ReportPurchasedMaterials',
		component: ReportPurchasedMaterials,
		meta: {
			title: 'Закупленные материалы',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/ordered-products',
		name: 'ReportOrderedProducts',
		component: ReportOrderedProducts,
		meta: {
			title: 'Проданные продукты',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/produced-products',
		name: 'ReportProducedProducts',
		component: ReportProducedProducts,
		meta: {
			title: 'Произведенные продукты',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/used-materials',
		name: 'ReportUsedMaterials',
		component: ReportUsedMaterials,
		meta: {
			title: 'Использованные материалы',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/materials-turnover-rates',
		name: 'ReportMaterialsTurnoverRates',
		component: ReportMaterialsTurnoverRates,
		meta: {
			title: 'Оборачиваемость материалов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/products-turnover-rates',
		name: 'ReportProductsTurnoverRates',
		component: ReportProductsTurnoverRates,
		meta: {
			title: 'Оборачиваемость продуктов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/resources-history',
		name: 'ReportResourcesHistory',
		component: ReportResourcesHistory,
		meta: {
			title: 'Использование ресурса',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/resources-turnover',
		name: 'ReportResourcesTurnover',
		component: ReportResourcesTurnover,
		meta: {
			title: 'Обороты ресурсов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-abc',
		name: 'ReportProductsAbc',
		component: ReportProductsAbc,
		meta: {
			title: 'ABC анализ продуктов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-abc',
		name: 'ReportMaterialsAbc',
		component: ReportMaterialsAbc,
		meta: {
			title: 'ABC анализ материалов',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/profit-and-loss',
		name: 'ReportProfitAndLoss',
		component: ReportProfitAndLoss,
		meta: {
			title: 'Прибыли и убытки',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/purchase-cost-dynamics',
		name: 'ReportPurchaseCostDynamics',
		component: ReportPurchaseCostDynamics,
		meta: {
			title: 'Динамика закупочных цен',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/production-cost-dynamics',
		name: 'ReportProductionCostDynamics',
		component: ReportProductionCostDynamics,
		meta: {
			title: 'Динамика себестоимости производства',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/order-price-dynamics',
		name: 'ReportOrderPriceDynamics',
		component: ReportOrderPriceDynamics,
		meta: {
			title: 'Динамика отпускных цен',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			title: 'Дашборд',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		meta: {
			title: 'Настройки',
			needAuth: true,
			authDeny: false
		},
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: {
			title: 'Панель администратора',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/companies',
		name: 'Companies',
		props: true,
		component: Companies,
		meta: {
			title: 'Компании',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/companies/:id',
		name: 'Company',
		props: true,
		component: Companies,
		meta: {
			title: 'Компания',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/invoices',
		name: 'Invoices',
		props: true,
		component: Invoices,
		meta: {
			title: 'Счета на оплату',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/logs',
		name: 'Logs',
		component: Logs,
		meta: {
			title: 'Логи',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/ui',
		name: 'UI Elements',
		component: UiElements,
		meta: {
			title: 'Ui элементы',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		// console.log('scroll from ' + from.path + ' to ' + to.path)
		if (savedPosition) {
			// console.log('scroll to saved position')
			return savedPosition
		} else if (from.path.split('/')[1] !== to.path.split('/')[1]) {
			// console.log('scroll to top 1')
			// window.scrollTo(0, 0)
			return { x: 0, y: 0 }
		} else if (to.name == 'AuditEdit') {
			// console.log('scroll to top 2')
			// window.scrollTo(0, 0)
			return { x: 0, y: 0 }
		} else if (to.hash) {
			// console.log('scroll to hash')
			return { selector: to.hash, offset: { x: 0, y: 200 } }
		} else {
			// console.log('else scroll to top')
			return { x: 0, y: 0 }
		}
	}
})

export default router

router.beforeEach((to, from, next) => {
	if (debug) console.log('router beforeEach')
	if (debug) console.log('-- to: ' + to.path)

	if (to.meta.needAuth) {
		if (debug) console.log('-- need authorization')
		if (store.getters.authorized) {
			if (debug) console.log('-- authorized, ok')

			if (!store.getters.user.onboarded) {
				if (debug) console.log('-- not onboarded')
				if (to.path != '/onboarding') {
					if (debug) console.log('-- redirect to onboarding')
					next('/onboarding')
				}
				else {
					if (debug) console.log('-- ok')
					next()
				}
			}
			else if (to.meta.authRight) {
				if (debug) console.log('---- need specific view right')
				if (store.getters.user[to.meta.authRight]) {
					if (debug) console.log('---- view right granted')
					next()
				}
				else {
					if (debug) console.log('---- view right rejected')

					store.dispatch("dialog/notifyOpen", {
						title: "Ошибка доступа",
						message: "Недостаточно прав для просмотра данной страницы"
					})
				}
			}
			else {
				next()
			}
		}
		else {
			if (debug) console.log('-- unauthorized')

			store.dispatch('set', {
				key: 'pathBeforeLogin',
				value: to.path
			})

			next('/login')
		}
	}
	else if (to.meta.authDeny) {
		if (debug) console.log('-- deny authorization ')
		if (store.getters.authorized) {
			if (debug) console.log('-- authorized, redirect')
			next('/materials')
		}
		else {
			if (debug) console.log('-- ok')
			next()
		}
	}
	else {
		next()
	}
})
